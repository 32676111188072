var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Nueva categoría")])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('section', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "title"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return [_c('b-field', {
                attrs: {
                  "horizontal": "",
                  "label": "Título*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "placeholder": "Nombre de la categoría"
                },
                model: {
                  value: _vm.categoryForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.categoryForm, "name", $$v);
                  },
                  expression: "categoryForm.name"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-field', {
          attrs: {
            "horizontal": "",
            "label": "Descripción"
          }
        }, [_c('b-input', {
          attrs: {
            "maxlength": "200",
            "type": "textarea",
            "placeholder": "Comentarios o explicaciones"
          },
          model: {
            value: _vm.categoryForm.description,
            callback: function callback($$v) {
              _vm.$set(_vm.categoryForm, "description", $$v);
            },
            expression: "categoryForm.description"
          }
        })], 1)], 1), _c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "visible"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return [_c('b-field', {
                attrs: {
                  "horizontal": "",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-switch', {
                attrs: {
                  "value": false,
                  "type": "is-success"
                },
                model: {
                  value: _vm.categoryForm.active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.categoryForm, "active", $$v);
                  },
                  expression: "categoryForm.active"
                }
              }, [_vm._v("Visible")])], 1)];
            }
          }], null, true)
        })], 1)])])]), _c('div', {
          staticClass: "hero-foot "
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": _vm.finishAddingCategory
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-success",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveOrUpdateCategory
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar' : 'Guardar'))])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }