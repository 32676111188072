var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [!_vm.showOrderQuestions && !_vm.addCategory ? _c('div', {
    attrs: {
      "id": "categoryTable"
    }
  }, [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  }, [_c('b-button', {
    staticClass: "button is-info is-primary",
    on: {
      "click": function click($event) {
        _vm.addCategory = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "plus"
    }
  }), _c('span', [_vm._v("Añadir categoría")])], 1)], 1)])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('section', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block is-full"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": "",
      "position": "is-right"
    }
  }, [_c('b-field', [_c('b-input', {
    attrs: {
      "placeholder": "Buscar categoría...",
      "type": "search",
      "icon": "search"
    },
    model: {
      value: _vm.filters.categorySearch,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "categorySearch", $$v);
      },
      expression: "filters.categorySearch"
    }
  })], 1), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Todos",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "visible", $$v);
      },
      expression: "filters.visible"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('question.all')) + " ")])], 1), _c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Visible",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "visible", $$v);
      },
      expression: "filters.visible"
    }
  }, [_c('v-icon', {
    attrs: {
      "icon": "eye"
    }
  })], 1)], 1), _c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Oculto",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "visible", $$v);
      },
      expression: "filters.visible"
    }
  }, [_c('v-icon', {
    attrs: {
      "icon": "eye-slash"
    }
  })], 1)], 1)]), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-link",
    on: {
      "click": _vm.fetchCategories
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "filter"
    }
  }), _vm._v(" Filtrar ")], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "block m-4"
  }, [_c('b-table', {
    attrs: {
      "backend-pagination": "",
      "backend-sorting": "",
      "data": _vm.data,
      "draggable": "",
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingCategories,
      "current-page": _vm.currentPage
    },
    on: {
      "sort": _vm.handleTableSort,
      "dragstart": _vm.dragstart,
      "drop": _vm.drop,
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "name",
      "label": "Categoría",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }], null, false, 2982927002)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "questions",
      "label": "Nº Preguntas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.questions) + " ")];
      }
    }], null, false, 135172704)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "active",
      "label": "Visible"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('v-icon', {
          staticClass: "pointer",
          attrs: {
            "icon": props.row.active ? 'eye' : 'eye-slash'
          }
        })];
      }
    }], null, false, 2955952232)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Ordenar preguntas",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleOrderQuestions(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "sort-numeric-up"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Editar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateCategory(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Eliminar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteCategory(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1)];
      }
    }], null, false, 1313963056)
  })], 1)], 1), _c('div', {
    staticClass: "block"
  }, [_c('el-pagination', {
    staticClass: "mt-5",
    attrs: {
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-sizes": [20, 50, 100, 200],
      "page-size": _vm.pageSize,
      "layout": "total, sizes, prev, pager, next",
      "total": _vm.totalElements
    },
    on: {
      "size-change": _vm.handlePageSizeChange,
      "current-change": _vm.handlePageChange,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)])])])])]) : _vm._e(), !_vm.showOrderQuestions && _vm.addCategory ? _c('div', {
    attrs: {
      "id": ""
    }
  }, [_c('add-category', {
    attrs: {
      "is-editing": _vm.isEditing,
      "category": _vm.selectedCategory
    },
    on: {
      "finishedAddingCategory": _vm.handleCategoryAdded
    }
  })], 1) : _vm._e(), _vm.showOrderQuestions ? _c('div', [_c('order-question', {
    attrs: {
      "category": _vm.selectedCategory
    },
    on: {
      "finishQuestionOrder": function finishQuestionOrder($event) {
        return _vm.resetStatus();
      }
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Categorías")])]);
}]

export { render, staticRenderFns }