<template>
  <div class="container">
    <div class="my-6">
    <h2 class="title is-2">Categoría: {{category.name}}</h2>
    </div>
    <h3 class="title is-3">Ordenar preguntas</h3>
    <p class="my-4">Para ordenar las preguntas, pinche en la pregunta cuyo orden desee modificar y arrástrela a su
      nueva
      posición</p>
    <div>
      <b-table
        default-sort="questionOrder"
        draggable
        :data="questionList"
        :loading="loadingQuestions"
        :hoverable="true"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave">
        <b-table-column centered field="questionOrder" label="Orden" sortable v-slot="props">
          {{ props.row.questionOrder }}
        </b-table-column>
        <b-table-column centered field="title" label="Pregunta" sortable v-slot="props">
          {{ props.row.title }}
        </b-table-column>
      </b-table>
    </div>
    <div class="block is-align-bottom">
      <div class="buttons is-right p-6">
        <b-button type="is-dark" @click="finishQuestionOrder()">Volver</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderQuestion',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      draggingQuestion: null,
      loadingQuestions: false,
      questionList: []
    }
  },
  methods: {
    dragstart (payload) {
      this.draggingQuestion = payload.row
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (event) {
      event.event.target.closest('tr').classList.remove('is-selected')
      const questionOrder = event.index + 1
      this.axios.put(process.env.VUE_APP_API_UPDATE_QUESTION_ORDER, null, {
        params: {
          categoryId: this.category.id,
          questionId: this.draggingQuestion.id,
          questionOrder: questionOrder
        }
      }).then(() => {
        this.$notify.success({
          title: 'Pregunta ' + this.draggingQuestion.name + ' actualizada',
          message: 'La questión ocupa la posición ' + questionOrder + ' dentro de la categoría'
        })
        this.fetchQuestions()
      })
    },
    fetchQuestions () {
      this.loadingQuestions = true
      this.axios.get(process.env.VUE_APP_API_GET_QUESTION, {
        params: {
          searchQuery: 'category.id:' + this.category.id,
          page: 0,
          size: 9999,
          sort: 'questionOrder,desc'
        }
      }).then(response => {
        this.questionList = response.data.content
      }).catch(err => {
        console.error('No se puede obtener el listado de preguntas ordenadas', err)
      }).finally(() => {
        this.loadingQuestions = false
      })
    },
    finishQuestionOrder () {
      this.$emit('finishQuestionOrder')
    }
  },
  created () {
    this.fetchQuestions()
  }
}
</script>

<style scoped lang="scss">

</style>
