<template>
<validation-observer ref="observer" tag="div" v-slot="{invalid}">
  <div class="hero-head">
    <div class="navbar">
      <div class="container">
        <div class="title">
          <h1>Nueva categoría</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="hero-body">
    <div class="container has-text-left">
      <section class="box">
        <div class="block">
          <validation-provider rules="required" name="title" v-slot="{errors, valid}">
            <b-field horizontal label="Título*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input placeholder="Nombre de la categoría" v-model="categoryForm.name"></b-input>
            </b-field>
          </validation-provider>
          <b-field horizontal label="Descripción">
            <b-input maxlength="200" type="textarea" placeholder="Comentarios o explicaciones" v-model="categoryForm.description"></b-input>
          </b-field>
        </div>
        <div class="block">
          <validation-provider rules="required" name="visible" v-slot="{errors, valid}">
          <b-field horizontal :type="{ 'is-danger': errors[0], 'is-success': valid }"
                   :message="errors">
            <b-switch :value="false" type="is-success" v-model="categoryForm.active">Visible</b-switch>
          </b-field>
          </validation-provider>
        </div>
      </section>
    </div>
  </div>
  <div class="hero-foot ">
    <div class="buttons is-right p-6">
      <b-button type="is-dark" @click="finishAddingCategory">Volver</b-button>
      <b-button type="is-success" :disabled="invalid" @click="saveOrUpdateCategory">{{ isEditing ? 'Actualizar' :
        'Guardar'
        }}</b-button>
    </div>
  </div>
</validation-observer>
</template>

<script>
export default {
  name: 'AddCategory',
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    category: {
      type: Object,
      required: false
    }
  },
  created () {
    if (this.isEditing) {
      this.categoryForm.id = this.category.id
      this.categoryForm.name = this.category.name
      this.categoryForm.description = this.category.description
      this.categoryForm.active = this.category.active
    }
  },
  data () {
    return {
      categoryForm: {
        id: null,
        name: null,
        description: null,
        active: true
      }
    }
  },
  methods: {
    finishAddingCategory () {
      this.$emit('finishedAddingCategory')
    },
    saveCategory () {
      this.axios.post(process.env.VUE_APP_API_CREATE_CATEGORY, this.categoryForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addCategory.created.title'),
          message: this.$i18n.t('addCategory.created.message')
        })
        this.$emit('finishedAddingCategory', true)
      }).catch(err => {
        console.error('No se ha podido crear la categoría', err)
      })
    },
    saveOrUpdateCategory () {
      if (this.isEditing) {
        this.updateCategory()
      } else {
        this.saveCategory()
      }
    },
    updateCategory () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_CATEGORY, this.categoryForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addCategory.updated.title'),
          message: this.$i18n.t('addCategory.updated.message')
        })
        this.$emit('finishedAddingCategory', true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
