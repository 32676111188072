<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">
    <div id="categoryTable" v-if="!showOrderQuestions && !addCategory">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>Categorías</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                  <b-button class="button is-info is-primary" @click="addCategory=true">
                    <v-icon icon="plus" class="g-icon"></v-icon>
                    <span>Añadir categoría</span>
                  </b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="block">
            <section class="box">
              <div class="block is-full">
                <b-field grouped group-multiline position="is-right">
                  <b-field>
                    <b-input placeholder="Buscar categoría..." type="search" icon="search"
                             v-model="filters.categorySearch"></b-input>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-radio-button v-model="filters.visible"
                                      name="visible"
                                      native-value="Todos"
                                      type="is-primary is-light">
                        {{ $t('question.all') }}
                      </b-radio-button>
                    </p>
                    <p class="control">
                      <b-radio-button v-model="filters.visible"
                                      name="visible"
                                      native-value="Visible"
                                      type="is-primary is-light">
                        <v-icon icon="eye"></v-icon>
                      </b-radio-button>
                    </p>
                    <p class="control">
                      <b-radio-button v-model="filters.visible"
                                      name="visible"
                                      native-value="Oculto"
                                      type="is-primary is-light">
                        <v-icon icon="eye-slash"></v-icon>
                      </b-radio-button>
                    </p>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button class="button is-link" @click="fetchCategories">
                        <v-icon icon="filter" class="g-icon"></v-icon>
                        Filtrar
                      </b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="block m-4">
                <b-table
                  backend-pagination
                  backend-sorting
                  :data="data"
                  @sort="handleTableSort"
                  draggable
                  @dragstart="dragstart"
                  @drop="drop"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  :hoverable="isHoverable"
                  :loading="loadingCategories"
                  :current-page.sync="currentPage">
                  <b-table-column centered field="id" label="REF" sortable v-slot="props">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column centered field="name" label="Categoría" sortable v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column centered field="questions" label="Nº Preguntas"  v-slot="props">
                    {{ props.row.questions }}
                  </b-table-column>
                  <b-table-column centered field="active" label="Visible"  v-slot="props">
                    <v-icon :icon="props.row.active ? 'eye':'eye-slash'" class="pointer"></v-icon>
                  </b-table-column>
                  <b-table-column centered label="Acciones" v-slot="props">
                    <el-tooltip class="item" effect="dark" content="Ordenar preguntas" placement="top">
                      <el-button size="mini" type="primary" circle @click="handleOrderQuestions(props.row)">
                        <v-icon icon="sort-numeric-up"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                      <el-button size="mini" type="warning" circle @click="handleUpdateCategory(props.row)">
                        <v-icon icon="pen"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                      <el-button size="mini" type="danger" circle @click="handleDeleteCategory(props.row)">
                        <v-icon icon="trash"></v-icon>
                      </el-button>
                    </el-tooltip>
                  </b-table-column>
                </b-table>
              </div>
              <div class="block">
                <el-pagination class="mt-5"
                               :hide-on-single-page="true"
                               @size-change="handlePageSizeChange"
                               @current-change="handlePageChange"
                               :current-page.sync="currentPage"
                               :page-sizes="[20, 50, 100, 200]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next"
                               :total="totalElements">
                </el-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div id="" v-if="!showOrderQuestions && addCategory">
      <add-category @finishedAddingCategory="handleCategoryAdded" :is-editing="isEditing"
                    :category="selectedCategory"></add-category>
    </div>
    <div v-if="showOrderQuestions">
      <order-question :category="selectedCategory" @finishQuestionOrder="resetStatus()"></order-question>
    </div>
  </section>
</template>

<script>
import AddCategory from '../components/category/AddCategory'
import OrderQuestion from '@/components/question/OrderQuestion'

export default {
  components: { AddCategory, OrderQuestion },
  data () {
    return {
      addCategory: false,
      currentPage: 1,
      categoryList: [],
      data: [],
      draggingRow: null,
      draggingRowIndex: null,
      filters: {
        categorySearch: '',
        selectedCategory: null,
        visible: 'Todos'
      },
      isEditing: false,
      isHoverable: true,
      loadingCategories: false,
      showOrderQuestions: false,
      sortOption: 'id,desc',
      pageSize: 20,
      rangeBefore: 3,
      rangeAfter: 1,
      selectedCategory: null,
      totalElements: 200
    }
  },
  methods: {
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      console.log(payload)
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      this.$buefy.toast.open(`Moved ${this.draggingRow.first_name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    },
    fetchCategories () {
      this.loadingCategories = true
      this.axios.get(process.env.VUE_APP_API_GET_CATEGORY, {
        params: {
          page: this.currentPage - 1,
          size: this.pageSize,
          sort: this.sortOption,
          searchQuery: this.getSearchQuery()
        }
      }).then(response => {
        const categories = response.data.content
        this.totalElements = response.data.totalElements
        this.data = categories.map(category => {
          return {
            id: category.id,
            name: category.name,
            description: category.description,
            questions: category.questions,
            active: category.active
          }
        })
      }).catch(err => {
        console.error('Error al obtener las categorías', err)
      }).finally(() => {
        this.loadingCategories = false
      })
    },
    handleCategoryAdded (isCreated) {
      if (isCreated) {
        this.fetchCategories()
      }
      this.resetStatus()
    },
    handleDeleteCategory (category) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Categoría',
        message: '¿Está seguro de que quiere <b>eliminar</b> la categoría? Esto eliminará las preguntas y respuestas asociadas a ésta',
        confirmText: 'Eliminar categoría',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_CATEGORY, {
            params: {
              categoryId: category.id
            }
          }).then(() => {
            this.$notify.success({
              title: 'Categoría eliminada',
              message: 'Categoría eliminada correctamente'
            })
            this.fetchCategories()
          }).catch(err => {
            console.log('No se puede eliminar la categoría', err)
          })
        }
      })
    },
    handleTableSort (field, order) {
      this.sortOption = field + ',' + order
      this.fetchCategories()
    },
    getSearchQuery () {
      let searchQuery = ''
      if (this.filters.categorySearch) {
        searchQuery += 'name$' + this.filters.categorySearch
      }
      if (this.filters.visible !== 'Todos') {
        searchQuery += ',active:' + (this.filters.visible === 'Visible')
      }
      return searchQuery
    },
    handleOrderQuestions (category) {
      this.selectedCategory = category
      this.showOrderQuestions = true
    },
    handleUpdateCategory (category) {
      this.selectedCategory = category
      this.isEditing = true
      this.addCategory = true
    },
    handlePageChange (val) {
      console.log(`current page: ${val}`)
    },
    handlePageSizeChange (val) {
      console.log(`${val} items per page`)
    },
    handleSelectCategory (value) {
      this.filters.selectedCategory = value
    },
    resetStatus () {
      this.addCategory = false
      this.selectedCategory = null
      this.isEditing = false
      this.showOrderQuestions = false
      this.sortOption = 'id,desc'
    }
  },
  created () {
    this.fetchCategories()
  }
}
</script>

<style lang="scss">

</style>
