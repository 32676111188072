var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "my-6"
  }, [_c('h2', {
    staticClass: "title is-2"
  }, [_vm._v("Categoría: " + _vm._s(_vm.category.name))])]), _c('h3', {
    staticClass: "title is-3"
  }, [_vm._v("Ordenar preguntas")]), _c('p', {
    staticClass: "my-4"
  }, [_vm._v("Para ordenar las preguntas, pinche en la pregunta cuyo orden desee modificar y arrástrela a su nueva posición")]), _c('div', [_c('b-table', {
    attrs: {
      "default-sort": "questionOrder",
      "draggable": "",
      "data": _vm.questionList,
      "loading": _vm.loadingQuestions,
      "hoverable": true
    },
    on: {
      "dragstart": _vm.dragstart,
      "drop": _vm.drop,
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "questionOrder",
      "label": "Orden",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.questionOrder) + " ")];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "title",
      "label": "Pregunta",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.title) + " ")];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "block is-align-bottom"
  }, [_c('div', {
    staticClass: "buttons is-right p-6"
  }, [_c('b-button', {
    attrs: {
      "type": "is-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.finishQuestionOrder();
      }
    }
  }, [_vm._v("Volver")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }